
.form__email-validate {
  margin-top: -25px;
  text-align: right;
  margin-bottom: 15px;
  font-size: 12px;
}

.form__email-validate--email {
  color: #2e4ce0;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.form__email-validate--domain {
  font-weight: 500;
}
