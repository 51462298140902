
.avatar-picker {
  display: flex;
  margin-bottom: 60px;
  align-items: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.file-input {
  display: none;
}

.image-preview {
  width: 100px;
  height: 100px;
  overflow: hidden;
  background-color: #fcfdfd;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  margin-right: 20px;
  flex-shrink: 0;
}

.avatar-picker--contain {
  .image-preview {
    background-size: contain;
  }
}

.avatar-picker__buttons-container {
  display: flex;
  flex-flow: column;
}

.avatar-picker__links-container {
  color: #8798ac;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 10px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.avatar-picker__upload-link,
.avatar-picker__remove-link {
  cursor: pointer;

  &:active {
    display: inline-block;
    outline: none;
    transform: translateY(2px);
  }
}

.avatar-picker__upload-link {
  color: #3152dc;
  margin-right: 10px;

  &:hover {
    color: #1335c3;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 0 14px;
  }
}

.avatar-picker__dot {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.avatar-picker__remove-link {
  color: #ff2373;
  margin-left: 10px;

  &:hover {
    color: #e20052;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.button__upload {
  background: url('../../../../assets/images/arrows/arrow-up-thick.svg') no-repeat 31px 12px #3152dc;
  background-size: 14px;
  color: #e5ebf0;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;
  padding: 15px 30px 15px 50px;
}

.avatar-picker__instructions {
  color: #8798ac;
  font-size: 10px;
  margin-top: 5px;
  line-height: 16px;
}
