
@import 'app/javascript/assets/styles/flags-sprite';

.wrapper {
  box-sizing: border-box;
  position: relative;
}

.iti-flag {
  display: inline-block;
}

.phone-number {
  height: 44px;
  padding: 5px 20px;
}

.clearfix {
  clear: both;
}
