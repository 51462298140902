
.has-errors,
.input-settings .has-errors {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[name="phone"] {
    border: solid 2px #ff2373;
    background-color: #fff2f7;
  }

  label {
    color: #ff2373;
  }

  .error-message {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: #ff2373;
  }
}
